import React from 'react'
import Box from './Box'
import { responsive } from './ThemeProvider/theme'
import Triangle from './Triangle'

const PageModule = ({ children, bg, mobilemt, ...props }) => {
  return (
    <Box.Relative
      bg={bg}
      mt={responsive(mobilemt || '1em', '-5.625em')}
      pt={responsive(0, '6.875em')}
      overflow="hidden"
      {...props}
    >
      <Triangle />
      {children}
    </Box.Relative>
  )
}

export default PageModule
