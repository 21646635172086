import { graphql } from 'gatsby'
import Page from '../containers/Blog/BlogPost'

export default Page

export const query = graphql`
query BlogPostQuery($slug: String!) {
  site {
    siteMetadata {
      siteUrl
    }
  }
  allWpPost(filter: { slug: { eq: $slug } }) {
    nodes {
      categories {
        nodes {
          name
          description
        }
      }
      date(formatString: "YYYY/MM/DD")
      content
      slug
      tags {
        nodes {
          name
        }
      }
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 1200
                height: 628
                transformOptions: {cropFocus: NORTH}
              )
            }
          }
        }
      }
    }
  }
}
`
