import React from 'react'
import { Flex, Stack } from '@chakra-ui/layout'
import { getSrc, getImage, GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'

import Container from '../../components/Container'
import Text from '../../components/Text'
import PageModule from '../../components/PageModule'
import Box from '../../components/Box'
import { responsive } from '../../components/ThemeProvider/theme'

import Layout from '../Layout'
import RenderContent from '../RenderContent'

const maxWidth = responsive('auto', '640px')

const BlogPost = ({ data }) => {
  const postData = data.allWpPost.nodes?.[0]
  if (!postData) return null
  const cat = postData.categories.nodes?.[0]
  const ogImage = postData.featuredImage?.node.localFile
  return (
    <Layout title={postData.title}>
      <Helmet>
        <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}${getSrc(ogImage)}`} />
      </Helmet>
      <Container py={responsive('1.5em', '3em')} maxWidth={maxWidth}>
        <Text.P>{cat?.name}</Text.P>
        <Text.Title as="h1">{postData.title}</Text.Title>
      </Container>
      <PageModule bg="custom.lightBlueBg" pt={responsive('2.75rem', '6.875em')} mt="0" pb={responsive('1.5em', '3em')}>
        <Container as={Stack} maxWidth={maxWidth}>
          {postData.tags.nodes.length ? (
            <>
            <Text.R>文章標籤</Text.R>
              <Flex flexWrap="wrap" mx="-0.5em">
                {postData.tags.nodes.map((tag, i) => (
                  <Box
                    rounded="full"
                    fontSize={responsive('0.75em', '1em')}
                    bg="white"
                    color="black"
                    px="1em"
                    py="0.5em"
                    border="2px solid"
                    key={i}
                    m="0.5em"
                  >
                    #{tag.name}
                  </Box>
                ))}
              </Flex>
            </>
          ) : null}
          <Stack spacing={responsive('0.5em', '1em')}>
            {/* <GatsbyImage image={getImage(ogImage)} /> */}
            <RenderContent>{postData.content}</RenderContent>
          </Stack>
        </Container>
      </PageModule>
      <Box
        borderWidth={responsive('30px 50vw 0 50vw', '90px 50vw 0 50vw')}
        borderColor="currentColor transparent transparent transparent"
        color="custom.lightBlueBg"
      />
      <Container as={Stack} py={responsive('1.5em', '3em')} maxWidth={maxWidth}>
        <Text.R>專欄簡介</Text.R>
        <Text.P as="h2">{cat?.name}</Text.P>
        <RenderContent>
          {cat?.description}
        </RenderContent>
      </Container>
    </Layout>
  )
}

export default BlogPost
